import { useMemo } from 'react'
import { type FirebaseStorage, ref } from 'firebase/storage'

export interface StorageRefOptions {
    baseURL?: string
    url?: string
}

function useStorageRef (storage: FirebaseStorage, options?: StorageRefOptions) {
    const url = useMemo(() => {
        if (options?.url !== undefined) {
            return `${options.baseURL ?? ''}${options.url}`
        }

        return options?.baseURL
    }, [options?.baseURL, options?.url])

    return useMemo(() => ref(storage, url), [storage, url])
}

export default useStorageRef
