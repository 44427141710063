import { type ForwardRefRenderFunction, type SVGProps, memo, forwardRef } from 'react'
import { useTheme } from '@mui/material'

const Climbing: ForwardRefRenderFunction<SVGSVGElement, SVGProps<SVGSVGElement>> = (props, ref) => {
    const theme = useTheme()

    const fill = theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white

    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox='0 0 1300 1280'
            preserveAspectRatio='xMidYMid meet' aria-label='Climbing' ref={ref} {...props}>
            <g transform="translate(0,1280) scale(0.1,-0.1)"
                fill={fill} stroke="none">
                <path d="M2032 12350 l-394 -448 -124 74 -124 75 -682 -564 c-376 -310 -689
                -571 -696 -579 -9 -12 -12 -269 -10 -1194 l3 -1178 445 559 c245 307 451 566
                458 574 18 21 -69 -147 -522 -1004 l-386 -730 1 -2205 c1 -1213 5 -2992 8
                -3954 l6 -1749 599 1989 c330 1094 610 2023 623 2065 l23 77 251 187 c137 104
                248 193 245 199 -2 6 -171 368 -375 805 -204 437 -370 795 -368 796 1 1 195
                -278 431 -621 236 -343 432 -624 435 -624 3 0 153 139 333 309 l327 308 731
                208 c615 175 734 206 755 197 37 -17 73 -89 113 -229 43 -148 64 -188 113
                -221 30 -22 41 -23 106 -18 40 3 105 17 145 32 250 89 379 96 903 49 380 -34
                683 -8 905 76 105 40 157 48 208 34 42 -13 167 -79 176 -94 3 -4 -2 -27 -11
                -50 -20 -50 -10 -95 28 -123 14 -11 66 -29 115 -42 103 -26 186 -63 412 -183
                114 -61 162 -92 162 -104 0 -10 -33 -47 -72 -84 -54 -50 -104 -82 -193 -127
                -213 -107 -380 -240 -432 -342 -56 -111 -38 -273 47 -412 199 -324 328 -747
                420 -1377 22 -147 26 -374 9 -459 -17 -83 -57 -199 -87 -250 -78 -135 -188
                -236 -482 -443 -210 -148 -270 -199 -296 -253 -23 -50 -12 -77 50 -117 81 -52
                153 -69 261 -62 191 13 407 115 592 279 94 84 145 110 282 148 130 35 175 56
                219 101 67 68 65 121 -10 251 -35 60 -42 82 -50 161 -11 102 -5 315 17 602 35
                453 47 704 41 853 -8 208 -27 261 -204 576 -12 23 -21 52 -20 66 3 24 6 25
                127 37 69 7 141 18 160 24 41 14 120 57 436 237 121 70 244 134 273 144 29 9
                101 24 160 33 170 25 223 36 295 66 241 97 436 319 482 548 7 36 16 119 20
                184 5 75 11 120 19 122 191 53 287 184 340 460 8 44 22 132 31 195 24 171 54
                279 150 550 134 373 154 459 134 569 -13 72 -42 120 -125 209 -79 86 -155 146
                -247 197 l-67 37 0 69 c0 57 3 69 17 69 14 0 15 12 10 106 -5 86 -11 117 -32
                159 -64 132 -247 224 -444 225 l-64 0 6 138 c3 76 11 168 18 204 l12 67 262
                115 263 114 242 -39 c134 -22 246 -38 250 -37 8 3 -786 1951 -797 1956 -5 2
                -115 45 -245 95 l-237 92 -795 805 c-437 443 -799 806 -804 808 -5 1 -323
                -236 -707 -527 -643 -487 -700 -528 -719 -516 -12 7 -250 146 -529 309 -503
                294 -508 297 -530 279 -12 -10 -226 -188 -475 -396 -400 -333 -455 -377 -470
                -365 -9 7 -341 297 -737 643 -396 347 -727 635 -735 642 -13 9 -81 -64 -408
                -437z m6234 -1685 c523 -682 954 -1247 959 -1255 4 -8 -5 -3 -21 11 -16 14
                -258 219 -539 454 -280 236 -517 434 -525 441 -13 11 -453 74 -518 74 -15 0
                -58 -59 -186 -262 -91 -144 -166 -258 -166 -253 0 6 43 317 97 693 53 375 93
                685 90 689 -4 3 -225 -24 -492 -61 -267 -37 -489 -66 -494 -64 -5 2 177 107
                405 233 l414 230 0 65 c0 105 12 262 20 253 4 -4 434 -566 956 -1248z m-2773
                366 c301 -167 546 -305 544 -307 -2 -2 -165 19 -363 46 -197 27 -368 50 -379
                50 -13 -1 -95 -81 -235 -230 -118 -126 -216 -228 -217 -226 -4 3 -123 1052
                -123 1078 0 13 25 3 113 -46 61 -34 359 -198 660 -365z m-4076 251 c144 -140
                138 -129 147 -272 10 -165 98 -352 227 -481 81 -81 146 -112 230 -113 82 0
                117 19 182 100 60 73 100 143 122 211 17 51 18 53 74 68 31 9 67 26 80 38 25
                23 99 131 144 208 16 29 53 67 99 101 40 31 119 102 176 158 57 56 107 99 110
                95 4 -4 6 -63 4 -132 l-4 -126 115 -112 c116 -114 127 -132 127 -205 0 -31 23
                -55 62 -65 15 -4 91 -62 170 -129 102 -87 155 -126 186 -135 45 -13 75 -52 56
                -71 -21 -21 -152 -40 -277 -40 -105 0 -144 4 -215 24 -288 80 -514 86 -685 19
                -40 -16 -57 -33 -113 -108 -107 -145 -148 -221 -148 -273 l-1 -45 310 -239
                c361 -278 403 -313 392 -324 -4 -4 -21 -3 -38 3 -71 27 -399 234 -584 370
                -561 409 -709 569 -911 986 -37 76 -47 110 -51 164 -4 41 -13 78 -23 93 -20
                28 -108 248 -120 299 -9 38 -4 61 14 61 7 0 71 -58 143 -128z m5687 -2551
                c-174 -342 -313 -625 -310 -628 3 -3 232 23 508 59 277 36 505 63 507 62 2 -2
                -25 -117 -59 -256 -36 -145 -65 -289 -69 -338 -11 -135 13 -223 76 -281 40
                -37 218 -154 348 -229 208 -120 263 -160 295 -216 28 -50 78 -100 170 -167 34
                -25 45 -40 43 -58 -1 -13 -31 -179 -67 -369 l-64 -345 -49 -44 c-109 -100
                -240 -161 -345 -161 -65 0 -148 22 -830 216 -317 90 -609 168 -649 174 -107
                15 -188 -1 -367 -71 -311 -124 -577 -184 -1010 -229 -464 -48 -646 -5 -811
                190 l-42 50 68 32 c37 17 172 97 298 177 221 139 238 148 434 222 112 43 204
                79 206 81 1 2 206 309 456 683 l454 680 560 696 c308 383 561 695 563 694 1
                -1 -140 -282 -314 -624z m1766 -77 c0 -10 -23 -48 -87 -147 -77 -117 -83 -144
                -83 -365 0 -225 26 -331 121 -498 55 -97 106 -148 194 -199 56 -32 74 -49 92
                -84 23 -45 22 -87 -1 -104 -15 -11 -124 0 -294 29 -174 30 -203 39 -317 103
                -91 52 -257 101 -338 101 -60 0 -85 15 -96 57 -11 45 -41 419 -41 513 0 47 7
                79 24 115 31 65 121 177 155 192 137 62 658 291 664 292 4 1 7 -2 7 -5z"/>
            </g>
        </svg>
    )
}

export default memo(forwardRef(Climbing))
