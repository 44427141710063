import { useState, useCallback } from 'react'
import { type Functions, type HttpsCallableOptions, httpsCallable } from 'firebase/functions'

function useHttpsCallable<RequestData = any, ResponseData = any> (functions: Functions, name: string, options?: HttpsCallableOptions) {
    const [loading, setLoading] = useState(false)

    const call = useCallback(async (data?: RequestData) => {
        try {
            setLoading(true)
            const result = await httpsCallable<RequestData, ResponseData>(functions, name, options)(data)
            return result.data
        } finally {
            setLoading(false)
        }
    }, [functions, name, options])

    return [call, loading] as [
        typeof call,
        typeof loading
    ]
}

export default useHttpsCallable
