import { type FC, memo, useCallback } from 'react'
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableBody,
    TableRow as MuiTableRow,
    TableCell,
    type TableCellProps,
    Checkbox
} from '@mui/material'
import { TableVirtuoso } from 'react-virtuoso'

export interface TableColumn extends Pick<TableCellProps, 'align' | 'padding'> {
    key: string
    name?: string
    width?: number
}

export interface TableProps<T = any> {
    emptyText?: string
    columns?: TableColumn[]
    rows?: T[]
    isRowSelected?: (index: number, row: T) => boolean
    onSelectRow?: (checked: boolean, index: number, row: T) => void
    onSelectAllRows?: (checked: boolean) => void
}

const Table: FC<TableProps> = (props) => {
    const {
        emptyText,
        columns,
        rows,
        isRowSelected,
        onSelectRow,
        onSelectAllRows
    } = props

    const fixedHeaderContent = useCallback(() => (
        <MuiTableRow>
            {typeof onSelectRow === 'function' && (
                <TableCell
                    padding='checkbox'
                    sx={{
                        backgroundColor: 'background.default'
                    }}
                >
                    <Checkbox
                        name='selectAll'
                        inputProps={{
                            'aria-label': 'Select all rows'
                        }}
                        disabled={
                            typeof onSelectAllRows !== 'function' ||
                            rows?.length === 0
                        }
                        checked={
                            rows !== undefined &&
                            rows.length > 0 &&
                            typeof isRowSelected === 'function' &&
                            rows.every((row, index) => (
                                typeof isRowSelected === 'function' &&
                                isRowSelected(index, row)
                            ))
                        }
                        onChange={(_event, checked) => {
                            if (typeof onSelectAllRows === 'function') {
                                onSelectAllRows(checked)
                            }
                        }}
                    />
                </TableCell>
            )}
            {columns?.map(({ key, name, align, width }) => (
                <TableCell
                    key={key}
                    align={align}
                    sx={{
                        width,
                        backgroundColor: 'background.default'
                    }}
                >
                    {name ?? key}
                </TableCell>
            ))}
        </MuiTableRow>
    ), [
        columns,
        rows,
        isRowSelected,
        onSelectRow,
        onSelectAllRows
    ])

    const itemContent = useCallback((index: number, row: any) => (
        <>
            {typeof onSelectRow === 'function' && (
                <TableCell padding='checkbox'>
                    <Checkbox
                        name={`selectRow${index}`}
                        inputProps={{
                            'aria-label': `Select row ${index}`
                        }}
                        checked={
                            typeof isRowSelected === 'function' &&
                            isRowSelected(index, row)
                        }
                        onChange={(_event, checked) => {
                            if (typeof onSelectRow === 'function') {
                                onSelectRow(checked, index, row)
                            }
                        }}
                    />
                </TableCell>
            )}
            {columns?.map(({ key, align, padding }) => (
                <TableCell
                    key={key}
                    align={align}
                    padding={padding}
                >
                    {row[key] ?? '-'}
                </TableCell>
            ))}
        </>
    ), [columns, isRowSelected, onSelectRow])

    const TableRow = useCallback((row: any) => (
        <MuiTableRow
            {...row}
            hover
            selected={
                typeof isRowSelected === 'function' &&
                isRowSelected(row['data-index'], row.item)
            }
        />
    ), [isRowSelected])

    const EmptyPlaceholder = useCallback(() => {
        let colSpan = columns?.length ?? 0

        if (typeof onSelectRow === 'function') {
            colSpan++
        }

        return (
            <TableBody>
                <MuiTableRow>
                    <TableCell
                        align='center'
                        colSpan={colSpan}
                        sx={{
                            color: 'text.secondary'
                        }}
                    >
                        {emptyText}
                    </TableCell>
                </MuiTableRow>
            </TableBody>
        )
    }, [emptyText, columns?.length, onSelectRow])

    return (
        <TableVirtuoso
            data={rows}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={itemContent}
            components={{
                Scroller: TableContainer,
                Table: MuiTable,
                TableHead,
                TableBody,
                TableRow,
                EmptyPlaceholder
            }}
        />
    )
}

Table.defaultProps = {
    emptyText: 'No rows found'
}

export default memo(Table)
