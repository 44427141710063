import { type FC, type ReactNode, memo, createContext, useContext, useEffect } from 'react'
import { firestore } from '../firebase'
import type { DocumentData } from '../types'
import { getStorageJSON } from '../utils'
import { useCollection, useSnapshot } from '../hooks'
import { UserContext } from './UserProvider'

export interface ProductsProviderProps {
    children: ReactNode
}

const initialProductDocs = getStorageJSON<DocumentData[]>(localStorage, 'products')

export const ProductsContext = createContext<ReturnType<typeof useSnapshot>>([
    initialProductDocs,
    false,
    undefined
])

const ProductsProvider: FC<ProductsProviderProps> = (props) => {
    const [user] = useContext(UserContext)
    const [productCollection] = useCollection(firestore, 'products')

    const [productDocs, productDocsLoading, productDocsError] = useSnapshot(productCollection, {
        initialState: initialProductDocs,
        disabled: user === null
    })

    useEffect(() => {
        if (productDocs !== undefined) {
            localStorage.setItem('products', JSON.stringify(productDocs))
        }
    }, [productDocs])

    return (
        <ProductsContext.Provider value={[productDocs, productDocsLoading, productDocsError]}>
            {props.children}
        </ProductsContext.Provider>
    )
}

export default memo(ProductsProvider)
