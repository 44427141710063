import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import reportWebVitals from './reportWebVitals'
import App from './App'
import {
    UserProvider,
    ThemeProvider,
    AlertProvider,
    FeedbackProvider,
    ProductsProvider
} from './providers'
import './index.css'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider>
                <UserProvider>
                    <ThemeProvider>
                        <AlertProvider>
                            <FeedbackProvider>
                                <ProductsProvider>
                                    <App />
                                </ProductsProvider>
                            </FeedbackProvider>
                        </AlertProvider>
                    </ThemeProvider>
                </UserProvider>
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
