import {
    type FC,
    type ReactNode,
    memo,
    createContext,
    useState,
    useMemo,
    useCallback
} from 'react'
import {
    type PaletteMode,
    ThemeProvider as MuiThemeProvider,
    CssBaseline
} from '@mui/material'
import { createTheme } from '../utils'

export interface ThemeProviderProps {
    children: ReactNode
}

const initialMode = localStorage.getItem('mode')

export const ThemeContext = createContext<[PaletteMode, (mode: PaletteMode) => void]>([
    (initialMode as PaletteMode) ?? 'light',
    (mode) => {
        console.log(mode)
    }
])

const ThemeProvider: FC<ThemeProviderProps> = (props) => {
    const [mode, setMode] = useState<PaletteMode>((initialMode as PaletteMode) ?? 'light')
    const theme = useMemo(() => createTheme(mode), [mode])

    const handleSetMode = useCallback((mode: PaletteMode) => {
        localStorage.setItem('mode', mode)
        setMode(mode)
    }, [])

    return (
        <ThemeContext.Provider value={[mode, handleSetMode]}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {props.children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

export default memo(ThemeProvider)
