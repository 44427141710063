import {
    type FC,
    type ReactNode,
    memo,
    createContext,
    useState,
    useCallback,
    useEffect
} from 'react'
import { type AlertProps, Snackbar, Alert as MuiAlert } from '@mui/material'

export interface AlertProviderProps {
    children: ReactNode
}

export interface Alert extends Pick<AlertProps, 'severity'> {
    content: ReactNode
}

export const AlertContext = createContext((alert: Alert) => {})

const AlertProvider: FC<AlertProviderProps> = (props) => {
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState<Alert>()

    const closeAlert = useCallback(() => {
        setOpen(false)
    }, [])

    useEffect(() => {
        if (alert !== undefined) {
            setOpen(true)
        }
    }, [alert])

    return (
        <AlertContext.Provider value={setAlert}>
            {props.children}
            <Snackbar
                autoHideDuration={5000}
                open={open}
                onClose={closeAlert}
            >
                <MuiAlert
                    severity={alert?.severity}
                    sx={{
                        width: '100%'
                    }}
                    onClose={closeAlert}
                >
                    {alert?.content}
                </MuiAlert>
            </Snackbar>
        </AlertContext.Provider>
    )
}

export default memo(AlertProvider)
