import { type FC, memo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@mui/material'
import { appName } from '../config'
import { Center } from '../components'

const NotFound: FC = () => {
    return (
        <Center>
            <Helmet>
                <title>Not Found | {appName}</title>
            </Helmet>
            <Typography
                variant='h5'
                sx={{
                    marginTop: 1
                }}
            >
                Not Found
            </Typography>
        </Center>
    )
}

export default memo(NotFound)
