import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { nodeEnv, firebaseConfig, resourceBucket, outputBucket } from './config'

export const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const firestore = getFirestore(app)

export const googleAuthProvider = new GoogleAuthProvider()

export const functions = getFunctions(app)

export const resourceStorage = getStorage(app, resourceBucket)

export const outputStorage = getStorage(app, outputBucket)

if (nodeEnv === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001)
    connectStorageEmulator(resourceStorage, 'localhost', 9199)
    connectStorageEmulator(outputStorage, 'localhost', 9199)
    connectFirestoreEmulator(firestore, 'localhost', 8080)
}
