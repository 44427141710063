import { type FC, memo, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { where } from 'firebase/firestore'
import { Stack, Typography, useTheme } from '@mui/material'
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts'
import { appName } from '../config'
import { firestore } from '../firebase'
import { useCollection, useCountFromServer } from '../hooks'
import { LinearProgress } from '../components'

const Home: FC = () => {
    const theme = useTheme()

    const dates = useMemo(() => {
        const dates = new Array(7).fill(0).map((_value, index) => {
            const sod = new Date()
            sod.setDate(sod.getDate() - index)
            sod.setHours(0, 0, 0, 0)
            return sod
        })

        dates.reverse()

        return dates
    }, [])

    const queryConstraints = useMemo(() => {
        return dates.map((date) => {
            const eod = new Date(date.getTime() + 86400000)

            return [
                where('startedAt', '>=', date),
                where('startedAt', '<', eod)
            ]
        })
    }, [dates])

    const resourceCollection = useCollection(firestore, 'resources', queryConstraints)
    const [resourceCounts, resourceCountsLoading] = useCountFromServer(resourceCollection)

    const data = useMemo(() => {
        return dates.map((date, index) => {
            return {
                Date: `${date.getMonth() + 1}/${date.getDate()}`,
                Uploads: resourceCounts?.[index] ?? 0
            }
        })
    }, [dates, resourceCounts])

    return (
        <>
            <Helmet>
                <title>Home | {appName}</title>
            </Helmet>
            <LinearProgress loading={resourceCountsLoading} />
            <Stack
                flex={1}
                spacing={2}
                paddingTop={2}
                alignItems='center'
                justifyContent='space-evenly'
            >
                <Typography variant='h6'>Usage within the last 7 days</Typography>
                <ResponsiveContainer height={400}>
                    <LineChart
                        data={data}
                        margin={{
                            top: 0,
                            right: 20,
                            left: -10,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid stroke={theme.palette.divider} />
                        <XAxis
                            dataKey='Date'
                            tick={{
                                fill: theme.palette.text.secondary,
                                fontSize: theme.typography.body2.fontSize
                            }}
                        />
                        <YAxis
                            allowDecimals={false}
                            tick={{
                                fill: theme.palette.text.secondary,
                                fontSize: theme.typography.body2.fontSize
                            }}
                        />
                        <Tooltip
                            contentStyle={{
                                borderColor: theme.palette.divider,
                                backgroundColor: theme.palette.background.default
                            }}
                        />
                        <Line
                            type='monotone'
                            dataKey='Uploads'
                            isAnimationActive={false}
                            stroke={theme.palette.primary.main}
                        />
                    </LineChart>
                </ResponsiveContainer>
                <Typography paddingX={2}>
                    The primary goal of our project, &#39;toolbox&#39;, is to curate a collection of simple yet powerful tools that streamline and enhance workflows in DevOps, DataOps, or MLOps environments. Imagine it as your reliable Swiss knife, providing versatile functionality to assist you in your work. The toolbox has been meticulously designed to be highly extensible, allowing us to continuously incorporate new tools and features based on your valuable feedback.
                    If you have any suggestions or specific tools that you believe would be a valuable addition to this toolbox – perhaps a specialized component for your own &#39;campartment&#39; of this Swiss knife – please don&#39;t hesitate to reach out and provide your feedback. Our team of engineers will carefully evaluate each suggestion and explore how it can be seamlessly integrated.
                    While our intention is to make this toolbox agnostic, supporting a wide range of environments, it&#39;s important to note that it extensively leverages <strong>Google Cloud</strong> resources. This enables us to fully harness the power of <strong>Google Cloud</strong> technologies while ensuring compatibility and optimal performance within those ecosystems.
                </Typography>
            </Stack>
        </>
    )
}

export default memo(Home)
