import { type FC, memo, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { UserContext, ProductsContext } from './providers'
import {
    SignInForm,
    AppContainer,
    Home,
    NotFound,
    Product,
    ProductExplorer,
    ProductViewer
} from './containers'
import { Center, Footer } from './components'

const App: FC = () => {
    const [user, loading] = useContext(UserContext)
    const [products] = useContext(ProductsContext)

    if (user === null) {
        return (
            <Center>
                {loading ? <CircularProgress /> : <SignInForm />}
            </Center>
        )
    }

    return (
        <AppContainer user={user}>
            <Routes>
                <Route
                    index
                    path='/'
                    Component={Home}
                />
                {products?.map((product) => (
                    <Route
                        key={product.id}
                        path={`/product/${product.id}`}
                        element={<Product product={product} />}
                    >
                        <Route
                            path='explorer/*'
                            element={
                                <ProductExplorer
                                    user={user}
                                    product={product}
                                />
                            }
                        />
                        <Route
                            path='viewer/*'
                            element={
                                <ProductViewer
                                    user={user}
                                    product={product}
                                />
                            }
                        />
                    </Route>
                ))}
                <Route
                    path='*'
                    Component={NotFound}
                />
            </Routes>
            <Footer />
        </AppContainer>
    )
}
export default memo(App)
