import { useState, useEffect } from 'react'
import { type Query, type FirestoreError, onSnapshot } from 'firebase/firestore'
import type { DocumentData as BaseDocumentData } from '../types'

export interface SnapshotOptions<DocumentData extends BaseDocumentData> {
    initialState?: DocumentData[]
    disabled?: boolean
}

function useSnapshot<DocumentData extends BaseDocumentData> (
    query: Query,
    options?: SnapshotOptions<DocumentData>
) {
    const [docs, setDocs] = useState(options?.initialState)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<FirestoreError>()

    useEffect(() => {
        setError(undefined)

        if (options?.disabled === true) {
            setDocs(options?.initialState)
            return
        }

        setLoading(true)

        const unsubscribe = onSnapshot(query, ({ docs }) => {
            setDocs(
                docs
                    .filter((snapshot) => snapshot.exists())
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    .map((snapshot) => ({
                        ...snapshot.data(),
                        id: snapshot.id
                    } as DocumentData))
            )

            setLoading(false)
        }, (error) => {
            setLoading(false)
            setError(error)
        })

        return () => {
            unsubscribe()
        }
    }, [query, options?.initialState, options?.disabled])

    return [docs, loading, error] as [
        typeof docs,
        typeof loading,
        typeof error
    ]
}

export default useSnapshot
