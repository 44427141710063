import {
    type FC,
    memo,
    useState,
    useContext,
    useMemo,
    useCallback,
    useEffect
} from 'react'
import { useParams, Link } from 'react-router-dom'
import type { User } from 'firebase/auth'
import { ref } from 'firebase/storage'
import {
    Toolbar,
    Breadcrumbs,
    Tooltip,
    IconButton,
    Badge,
    Link as MuiLink,
    Typography,
    Zoom,
    useTheme,
    useMediaQuery
} from '@mui/material'
import {
    FiTrash2,
    FiUpload,
    FiRefreshCw,
    FiLink,
    FiFolder,
    FiFile
} from 'react-icons/fi'
import { type FileWithPath } from 'react-dropzone'
import { firestore, functions, resourceStorage } from '../firebase'
import type { DocumentData } from '../types'
import {
    useCollection,
    useSnapshot,
    useStorageRef,
    useListAll,
    useHttpsCallable,
    useUploadBytes
} from '../hooks'
import { AlertContext } from '../providers'
import { type TableColumn, LinearProgress, Table, Dropzone } from '../components'

export interface ProductExplorerProps {
    user: User
    product: DocumentData
}

const columns: TableColumn[] = [
    {
        key: 'name',
        name: 'Name',
        width: 300
    },
    {
        key: 'status',
        name: 'Status',
        width: 100
    },
    {
        key: 'size',
        name: 'Size',
        width: 100
    },
    {
        key: 'contentType',
        name: 'Type',
        width: 200
    },
    {
        key: 'startedAt',
        name: 'Started At',
        width: 210
    },
    {
        key: 'endedAt',
        name: 'Ended At',
        width: 210
    }
]

const mobileColumns = columns.slice(0, 1)

const ProductExplorer: FC<ProductExplorerProps> = (props) => {
    const { user, product } = props
    const params = useParams()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))
    const [selected, setSelected] = useState(new Set<number>())
    const setAlert = useContext(AlertContext)
    const [resourceCollection] = useCollection(firestore, `users/${user.uid}/resources`)
    const [resourceDocs, resourceDocsLoading] = useSnapshot(resourceCollection)
    const [uploadBytes, uploadBytesProgress, uploadBytesItem] = useUploadBytes()
    const title = product.name ?? product.id

    const resourceDocMap = useMemo(() => (
        new Map(resourceDocs?.map((doc) => [doc.name, doc]))
    ), [resourceDocs])

    const resourceURL = params['*'] ?? ''

    const breadcrumbs = useMemo(() => (
        resourceURL
            .split('/')
            .filter((breadcrumb) => breadcrumb.length > 0)
    ), [resourceURL])

    const resourceRef = useStorageRef(resourceStorage, {
        baseURL: `${user.uid}/${product.id}/`,
        url: resourceURL
    })

    const [
        resourceExplorer,
        resourceExplorerLoading,
        refreshResourceExplorer
    ] = useListAll(resourceRef)

    const [deleteFiles, deletingFiles] = useHttpsCallable(functions, 'deleteFiles')

    const resourceRefs = useMemo(() => (
        (resourceExplorer?.prefixes ?? []).concat(resourceExplorer?.items ?? [])
    ), [resourceExplorer?.prefixes, resourceExplorer?.items])

    useEffect(() => {
        setSelected(new Set())
    }, [resourceRefs])

    const rows = useMemo(() => (
        resourceRefs.map(({ name, fullPath }, index) => {
            const prefix = index < (resourceExplorer?.prefixes?.length ?? 0)
            const nextResourceURL = resourceURL.length > 0 ? `${resourceURL}/${name}` : name
            const url = prefix ? nextResourceURL : `/product/${product.id}/viewer/${nextResourceURL}`
            const resourceDoc = resourceDocMap.get(fullPath)
            const contentType = prefix ? 'Folder' : resourceDoc?.contentType
            const Icon = prefix ? FiFolder : FiFile

            const startedAt = typeof resourceDoc?.startedAt?.seconds === 'number'
                ? new Date(resourceDoc.startedAt.seconds * 1000).toLocaleString([], {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                    second: '2-digit'
                })
                : undefined

            const endedAt = typeof resourceDoc?.endedAt?.seconds === 'number'
                ? new Date(resourceDoc.endedAt.seconds * 1000).toLocaleString([], {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                    second: '2-digit'
                })
                : undefined

            return {
                name: (
                    <MuiLink
                        to={url}
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        component={Link}
                    >
                        <Icon style={{
                            fontSize: 16,
                            minWidth: 16
                        }} />
                        <Typography
                            variant='inherit'
                            sx={{
                                marginLeft: 1
                            }}
                        >
                            {name}
                        </Typography>
                    </MuiLink>
                ),
                status: (
                    <Typography
                        variant='inherit'
                        sx={{
                            color: resourceDoc?.status === 'Success'
                                ? 'success.main'
                                : resourceDoc?.status === 'Error'
                                    ? 'error.main'
                                    : resourceDoc?.status !== undefined
                                        ? 'info.main'
                                        : undefined
                        }}
                    >
                        {resourceDoc?.status ?? '-'}
                    </Typography>
                ),
                size: typeof resourceDoc?.size === 'string'
                    ? `${resourceDoc.size} B`
                    : undefined,
                contentType,
                startedAt,
                endedAt,
                url
            }
        })
    ), [
        product.id,
        resourceURL,
        resourceDocMap,
        resourceExplorer?.prefixes?.length,
        resourceRefs
    ])

    const isRowSelected = useCallback((index: number) => (
        selected.has(index)
    ), [selected])

    const selectRow = useCallback((checked: boolean, index: number) => {
        setSelected((selected) => {
            const next = new Set(selected)

            if (checked) {
                next.add(index)
            } else {
                next.delete(index)
            }

            return next
        })
    }, [])

    const selectAllRows = useCallback((checked: boolean) => {
        if (checked) {
            setSelected(new Set(rows.keys()))
        } else {
            setSelected(new Set())
        }
    }, [rows])

    const deleteSelectedRows = useCallback(async () => {
        const selectedResourceRefs = Array.from(selected)
            .map((index) => resourceRefs[index])

        try {
            await deleteFiles({
                files: selectedResourceRefs.map((selectedResourceRef) => ({
                    bucket: selectedResourceRef.bucket,
                    prefix: selectedResourceRef.fullPath
                }))
            })

            setAlert({
                severity: 'success',
                content: 'Successfully deleted files'
            })

            void refreshResourceExplorer()
        } catch (error) {
            setAlert({
                severity: 'error',
                content: 'Failed to delete some files'
            })
        }
    }, [selected, resourceRefs, refreshResourceExplorer, deleteFiles])

    const uploadFiles = useCallback(async (files: FileWithPath[]) => {
        try {
            await uploadBytes(files.map((file) => {
                const name = file.path ?? file.name
                const url = `${resourceRef.fullPath}/${name}`
                return {
                    ref: ref(resourceStorage, url),
                    data: file
                }
            }))

            setAlert({
                severity: 'success',
                content: 'Successfully uploaded files'
            })

            void refreshResourceExplorer()
        } catch (error) {
            setAlert({
                severity: 'error',
                content: 'Failed to upload some files'
            })
        }
    }, [refreshResourceExplorer, uploadBytes])

    return (
        <>
            <LinearProgress loading={
                resourceDocsLoading ||
                resourceExplorerLoading ||
                deletingFiles
            } />
            <Toolbar>
                <Tooltip title='Copy Path'>
                    <IconButton
                        sx={{
                            marginRight: 1
                        }}
                        onClick={() => {
                            void navigator
                                .clipboard
                                .writeText(window.location.href)
                        } }
                    >
                        <FiLink />
                    </IconButton>
                </Tooltip>
                <Breadcrumbs sx={{
                    flex: 1
                }}>
                    <MuiLink
                        to=''
                        component={Link}
                    >
                        {title}
                    </MuiLink>
                    {breadcrumbs?.map((breadcrumb, index) => {
                        const url = breadcrumbs
                            .slice(0, index + 1)
                            .join('/')

                        return (
                            <MuiLink
                                key={index}
                                to={url}
                                component={Link}
                            >
                                {breadcrumb}
                            </MuiLink>
                        )
                    })}
                </Breadcrumbs>
                <Zoom in={selected.size > 0}>
                    <Tooltip title={`Delete (${selected.size})`}>
                        <IconButton
                            sx={{
                                marginLeft: 1
                            }}
                            onClick={deleteSelectedRows}
                        >
                            <Badge
                                color='primary'
                                badgeContent={selected.size}
                            >
                                <FiTrash2 />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Zoom>
                <Dropzone
                    noDrag
                    BoxProps={{
                        marginLeft: 1
                    }}
                    onDrop={uploadFiles}
                >
                    <Tooltip title='Upload'>
                        <IconButton>
                            <FiUpload />
                        </IconButton>
                    </Tooltip>
                </Dropzone>
                <Tooltip title='Refresh'>
                    <IconButton
                        sx={{
                            marginLeft: 1
                        }}
                        onClick={refreshResourceExplorer}
                    >
                        <FiRefreshCw />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <Dropzone
                noClick
                progress={uploadBytesProgress}
                progressLabel={uploadBytesItem?.ref.name}
                BoxProps={{
                    flex: 1
                }}
                onDrop={uploadFiles}
            >
                <Table
                    emptyText='No files found'
                    columns={mobile ? mobileColumns : columns}
                    rows={rows}
                    isRowSelected={isRowSelected}
                    onSelectRow={selectRow}
                    onSelectAllRows={selectAllRows}
                />
            </Dropzone>
        </>
    )
}

export default memo(ProductExplorer)
