import {
    type FC,
    type ReactNode,
    memo,
    createContext,
    useState,
    useEffect
} from 'react'
import { type User, onIdTokenChanged } from 'firebase/auth'
import { auth } from '../firebase'

export interface UserProviderProps {
    children: ReactNode
}

export const UserContext = createContext<[User | null, boolean]>([auth.currentUser, false])

const UserProvider: FC<UserProviderProps> = (props) => {
    const [user, setUser] = useState(auth.currentUser)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(auth, (user) => {
            setUser(user)
            setLoading(false)
        })

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <UserContext.Provider value={[user, loading]}>
            {props.children}
        </UserContext.Provider>
    )
}

export default memo(UserProvider)
