import { type FC, type ReactNode, memo } from 'react'
import { type DropzoneOptions, useDropzone } from 'react-dropzone'
import { type BoxProps, Dialog, Box, Stack, Typography, Fade } from '@mui/material'
import { IconContext } from 'react-icons'
import { FaCheckCircle, FaFileUpload } from 'react-icons/fa'

export interface DropzoneProps extends DropzoneOptions {
    progress?: number
    progressLabel?: string
    BoxProps?: BoxProps
    children?: ReactNode
}

const Dropzone: FC<DropzoneProps> = (props) => {
    const { progress, progressLabel, BoxProps, children, ...others } = props
    const { isDragActive, getRootProps, getInputProps } = useDropzone(others)

    return (
        <>
            <Box
                {...getRootProps()}
                {...BoxProps}
            >
                <input {...getInputProps()} />
                {children}
            </Box>
            <Dialog
                {...getRootProps()}
                fullWidth
                maxWidth='xs'
                open={progress !== undefined || isDragActive}
            >
                <input {...getInputProps()} />
                <Stack
                    spacing={2}
                    paddingX={2}
                    paddingY={5}
                    alignItems='center'
                    justifyContent='center'
                >
                    <IconContext.Provider value={{ size: '80px' }}>
                        <FaFileUpload />
                    </IconContext.Provider>
                    <Typography>
                        Drag and drop files or folders to upload
                    </Typography>
                    <Fade
                        mountOnEnter
                        in={progress !== undefined && progressLabel !== undefined}
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                maxWidth: '100%',
                                color: 'text.secondary'
                            }}
                        >
                            {progressLabel}
                        </Typography>
                    </Fade>
                    <Fade
                        mountOnEnter
                        in={progress !== undefined}
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                color: 'text.secondary'
                            }}
                        >
                            Uploading...{progress ?? 100}% {(progress ?? 100) === 100 && (<FaCheckCircle />)}
                        </Typography>
                    </Fade>
                </Stack>
            </Dialog>
        </>
    )
}

export default memo(Dropzone)
