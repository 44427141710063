import { useState, useCallback, useEffect } from 'react'
import { type StorageReference, getDownloadURL } from 'firebase/storage'

export interface DownloadURLOptions {
    disabled?: boolean
}

function useDownloadURL (ref: StorageReference, options?: DownloadURLOptions) {
    const [downloadURL, setDownloadURL] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const refresh = useCallback(async () => {
        setError(undefined)

        if (options?.disabled === true) {
            setDownloadURL(undefined)
            return
        }

        try {
            setLoading(true)
            const downloadURL = await getDownloadURL(ref)
            setDownloadURL(downloadURL)
        } catch (error) {
            if (error instanceof Error) {
                setError(error)
            }
        } finally {
            setLoading(false)
        }
    }, [ref, options?.disabled])

    useEffect(() => {
        void refresh()
    }, [refresh])

    return [downloadURL, loading, refresh, error] as [
        typeof downloadURL,
        typeof loading,
        typeof refresh,
        typeof error
    ]
}

export default useDownloadURL
