function getStorageJSON<T = any> (storage: Storage, key: string) {
    try {
        const item = storage.getItem(key)

        if (item !== null) {
            return JSON.parse(item) as T
        }
    } catch (error) {
        storage.removeItem(key)
    }
}

export default getStorageJSON
