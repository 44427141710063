import { type PaletteMode, createTheme as createMuiTheme } from '@mui/material'

function createTheme (mode: PaletteMode) {
    return createMuiTheme({
        palette: {
            mode
        },
        components: {
            MuiToolbar: {
                defaultProps: {
                    sx: {
                        paddingY: 1
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'unset'
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        tableLayout: 'fixed',
                        borderCollapse: 'separate'
                    }
                }
            },
            MuiLink: {
                defaultProps: {
                    color: 'inherit'
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word'
                    }
                }
            },
            MuiIconButton: {
                defaultProps: {
                    size: 'small',
                    color: 'inherit'
                }
            },
            MuiButton: {
                defaultProps: {
                    variant: 'outlined'
                }
            },
            MuiSwitch: {
                defaultProps: {
                    size: 'small'
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    size: 'small'
                }
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        height: 2
                    }
                }
            }
        }
    })
}

export default createTheme
