import { type FC, type SVGProps, memo } from 'react'
import { useTheme } from '@mui/material'

const Logo: FC<SVGProps<SVGSVGElement>> = (props) => {
    const theme = useTheme()

    const fill = theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white

    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0.001 0.004 173.998 36.355" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175 37" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" data-type="ugc" role="presentation" aria-hidden="true" aria-label="OpsAngels" {...props}>
            <g fill={fill}>
                <path d="M22.582 28.836c9.585-6.816 7.09-20.581-3.532-24.175-.104.308-.184.553-.284.748-.27.53-.454.755-.86 1.193-.26.275-.568.472-1.134.732-.05.023-.155.245-.198.32-.06.104-.117.301-.159.344 4.604.73 8.17 4.959 8.22 9.611.03 2.974-1.51 6.692-5.413 8.682 1.697.137 3 .76 3.36 2.545Z"></path>
                <path d="M14.386 3.951c.249 0-1.013.613-1.912 1.733-.503.626-.334 1.16-.828 1.96-.43.697-1.477 1.625-2.435 2.525-.397.373 2.152-1.779 2.44-1.277.11.19-.537 2.35-2.706 4.294-.798.709-1.212 4.742-1.528 5.758-.49 1.566 1.612-2.94 1.612-1.696 0 1.343-.418 2.956.14 4.154.08.17.384-2.462.894-1.787.135.178.781.842 1.38 1.903.17.29-2.085 2.653-.455 6.176 1.43 3.105 8.784 2.59 6.277 3.17-2.03.473-8.924.46-12.495-1.425-.33-.178 3.42-.551 2.216-.49-2.744.138-4.702-1.533-5.652-3.087-.371-.614 3.225 1.488 2.617 1.11-2.55-1.579-3.254-4.588-3.609-6.001C-.705 16.809.84 12.54 2.55 10.069 4.525 7.217 9.57 3.98 14.386 3.951Z"></path>
                <path d="M30.844 27.175v3.694h3.95v-8.845c-1.356 1.563-2.698 3.55-3.95 5.15Z"></path>
                <path d="M34.779 16.651V4.293h-3.95v18.835c1.356-3.025 2.748-2.675 3.95-6.477Z"></path>
                <path d="M36.828 4.296h4.201c3.845.078 6.654 2.118 7.942 5.535.33.879.51 1.858.571 2.985.075 1.306-.172 2.535-.487 3.472-1.14 3.404-4.032 5.695-8.185 5.735h-3.877c.134-1.172 3.419-.905 2.872-3.795.622.006 1.208 0 1.41-.022.924-.1 1.583-.28 2.534-.94 1.283-.887 2.07-2.83 1.83-4.726-.282-2.228-1.688-4.205-4.575-4.364a47.972 47.972 0 0 0-1.114-.008c-.549-.765.86-1.661-3.122-3.872Z"></path>
                <path d="M62.357 7.953c2.165.487 3.411 1.659 3.768 3.21l3.885-.164c-.675-3.943-3.367-5.915-6.58-6.676-2.003 1.148-2.327 2.364-1.073 3.63Z"></path>
                <path d="M59.584 19.313c1.848-.954.172-4.126-1.292-4.089-2.437-.49-3.333-1.7-3.295-3.4.039-1.79 1.101-2.824 2.894-3.53-1.502-1.255-.671-2.664.859-4.093-2.366.379-4.66 1.506-6.006 3.142a7.253 7.253 0 0 0-1.627 5.407c.222 2.068.99 3.308 1.98 4.217 1.494 1.35 3.77 2.044 6.487 2.346Z"></path>
                <path d="M50.867 23.204c.167 4.359 2.855 6.948 7.603 7.688-.46-1.408-.673-2.478-.665-4.14-1.937-.775-2.959-2.014-2.986-3.629l-3.952.081Z"></path>
                <path d="M63.043 15.884c.092.257 1.32 1.034 1.566 2.155.242 1.108-.62 1.899-.613 1.901 1.892.512 2.726 1.294 2.776 2.897.065 2.153-1.75 3.79-4.6 4.308.064 1.486.581 2.858 1.013 3.691 4.57-.997 7.497-3.934 7.549-7.69.057-4.149-2.754-6.42-7.691-7.262Z"></path>
                <path d="M108.809 29.103h-3.868v-13.11c0-1.238-.367-2.177-1.103-2.816-.644-.561-1.5-.842-2.567-.842-1.271 0-2.35.37-3.235 1.108-1.094.913-1.64 2.247-1.64 4.006v11.654h-3.869V9.693h2.124l1.431 2.355c1.439-1.87 3.461-2.803 6.067-2.803 2.048 0 3.657.567 4.829 1.701 1.221 1.184 1.83 2.866 1.83 5.048v13.11Z" fillRule="nonzero"></path>
                <path d="M129.545 27.408c0 2.675-.821 4.839-2.465 6.494-1.62 1.638-3.707 2.457-6.258 2.457-.704 0-1.608-.11-2.71-.328-1.167-.231-2.014-.483-2.54-.753l.864-3.645c1.29.926 2.99 1.389 5.1 1.389 2.643 0 4.018-1.995 4.128-5.982-1.253 1.563-3.008 2.344-5.265 2.344-2.206 0-4.05-.91-5.536-2.724-1.552-1.9-2.329-4.361-2.329-7.382 0-2.983.704-5.35 2.111-7.104 1.43-1.789 3.387-2.683 5.87-2.683 2.188 0 3.912.804 5.169 2.41l1.92-2.163h1.941v17.67Zm-3.875-8.004c0-4.49-1.504-6.735-4.515-6.735-3.01 0-4.514 2.245-4.514 6.735 0 4.485 1.504 6.728 4.514 6.728 3.01 0 4.515-2.243 4.515-6.728Z" fillRule="nonzero"></path>
                <path d="M151.078 19.73H137.39c.055 2.11.626 3.712 1.716 4.808.985.996 2.304 1.494 3.957 1.494 2.188 0 3.884-.757 5.087-2.27l2.015 3.065c-.83.756-1.887 1.366-3.17 1.83-1.282.462-2.546.694-3.79.694-2.764 0-5.066-.827-6.905-2.478-2.02-1.815-3.03-4.3-3.03-7.456 0-3.203.936-5.698 2.806-7.483 1.706-1.628 3.931-2.443 6.674-2.443 2.21 0 4.129.718 5.754 2.15 1.811 1.602 2.717 3.707 2.717 6.314 0 .58-.048 1.17-.143 1.776Zm-4.14-2.989c-.014-1.3-.49-2.325-1.43-3.077-.831-.663-1.834-.995-3.01-.995-2.607 0-4.22 1.357-4.842 4.072h9.281Z" fillRule="nonzero"></path>
                <path d="M154.128 2.58V.004h3.868v4.688c-1.979-.88-3.413-1.754-3.868-2.11Z"></path>
                <path d="M154.128 5.341v23.766h3.868V7.332c-1.147-.427-2.326-1.01-3.868-1.99Z"></path>
                <path d="M173.938 24.738c-.204 1.397-.93 2.52-2.178 3.37-1.222.828-2.709 1.243-4.462 1.243-2.528 0-4.616-.686-6.264-2.057l1.852-3.185c1.153 1.443 2.634 2.164 4.44 2.164 1.548 0 2.436-.503 2.663-1.51.054-.23.081-.44.081-.626 0-.68-.424-1.33-1.273-1.949a456.37 456.37 0 0 1-4.283-2.871c-2.016-1.411-3.024-3.01-3.024-4.799 0-.401.05-.812.15-1.235.595-2.558 2.672-3.838 6.231-3.838 1.012 0 2.104.213 3.276.64 1.171.428 1.99.917 2.457 1.47l-1.777 3.077c-.975-1.407-2.344-2.11-4.106-2.11-1.248 0-1.97.414-2.166 1.241a1.836 1.836 0 0 0-.054.435c0 .813.525 1.581 1.573 2.303a656.6 656.6 0 0 1 3.82 2.456c2.07 1.362 3.105 3.008 3.105 4.94 0 .276-.02.556-.06.84Z" fillRule="nonzero"></path>
                <path d="m88.598 16.107-.007 12.996h-1.594l-1.893-2.136c-1.289 1.588-2.962 2.384-5.019 2.384-1.88 0-3.45-.591-4.712-1.77-1.29-1.205-1.934-2.737-1.934-4.592 0-1.994.65-3.569 1.948-4.726 1.285-1.148 3.02-1.723 5.209-1.723h4.134c-.032-1.396-.466-2.447-1.301-3.15-.695-.579-1.575-.868-2.642-.868-1.74 0-3.278.542-4.617 1.628l-1.185-3.417c.595-.343 1.54-.648 2.84-.914 1.203-.255 2.19-.381 2.962-.381 2.22 0 4.031.528 5.434 1.582 1.584 1.189 2.377 2.884 2.377 5.087Zm-3.875 5.96v-2.529h-3.446c-1.235 0-2.201.29-2.9.874-.7.583-1.05 1.388-1.05 2.416 0 .93.32 1.704.96 2.324.663.635 1.51.953 2.54.953 1.113 0 2.014-.309 2.704-.927.795-.712 1.192-1.749 1.192-3.11Z" fillRule="nonzero"></path>
            </g>
        </svg>
    )
}

export default memo(Logo)
