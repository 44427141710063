import {
    type FC,
    type ReactNode,
    memo,
    createContext,
    useState,
    useContext,
    useCallback,
    useEffect
} from 'react'
import { Dialog, Stack, Typography, Rating, TextField, Button } from '@mui/material'
import { functions } from '../firebase'
import { useHttpsCallable } from '../hooks'
import { LinearProgress } from '../components'
import { AlertContext } from './AlertProvider'

export interface FeedbackProviderProps {
    children: ReactNode
}

export const FeedbackContext = createContext(() => {})

const FeedbackProvider: FC<FeedbackProviderProps> = (props) => {
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState<Record<string, any>>()
    const setAlert = useContext(AlertContext)
    const [sendFeedback, sendingFeedback] = useHttpsCallable(functions, 'sendFeedback')

    const toggleOpen = useCallback(() => {
        setOpen((open) => !open)
    }, [])

    useEffect(() => {
        if (!open) {
            setValues(undefined)
        }
    }, [open])

    return (
        <FeedbackContext.Provider value={toggleOpen}>
            <LinearProgress loading={sendingFeedback} />
            {props.children}
            <Dialog
                fullWidth
                keepMounted
                maxWidth='xs'
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        padding: 2
                    }}
                >
                    How are things going so far?
                </Typography>
                <Stack
                    padding={2}
                    spacing={2}
                    component='form'
                    onSubmit={async (event) => {
                        try {
                            event.preventDefault()
                            await sendFeedback(values)
                            setOpen(false)

                            setAlert({
                                severity: 'success',
                                content: 'Thank you for your feedback!'
                            })
                        } catch (error) {
                            setAlert({
                                severity: 'error',
                                content: 'Failed to send feedback'
                            })
                        }
                    }}
                >
                    <Typography sx={{
                        color: 'text.secondary'
                    }}>
                        Your feedback is greatly appreciated, and helps us improve.
                    </Typography>
                    <Rating
                        disabled={sendingFeedback}
                        value={values?.rating ?? null}
                        onChange={(_event, rating) => {
                            setValues({
                                ...values,
                                rating
                            })
                        }}
                    />
                    <TextField
                        multiline
                        rows={5}
                        name='comment'
                        label='Comment (Optional)'
                        disabled={sendingFeedback}
                        value={values?.comment ?? ''}
                        onChange={(event) => {
                            setValues({
                                ...values,
                                comment: event.target.value
                            })
                        }}
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        disabled={
                            sendingFeedback ||
                            values?.rating === undefined ||
                            values.rating === null
                        }
                    >
                        Send Feedback
                    </Button>
                    <Button
                        variant='text'
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Close
                    </Button>
                </Stack>
            </Dialog>
        </FeedbackContext.Provider>
    )
}

export default memo(FeedbackProvider)
