import { useState, useCallback, useEffect } from 'react'
import { type Query, getCountFromServer } from 'firebase/firestore'

function useCountFromServer (queries?: Query[]) {
    const [counts, setCounts] = useState<number[]>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const refresh = useCallback(async () => {
        setError(undefined)

        if (queries?.length === undefined || queries.length === 0) {
            setCounts(undefined)
            return
        }

        try {
            setLoading(true)

            const counts = await Promise.all(queries.map(async (query) => {
                return (await getCountFromServer(query)).data().count
            }))

            setCounts(counts)
        } catch (error) {
            if (error instanceof Error) {
                setError(error)
            }
        } finally {
            setLoading(false)
        }
    }, [queries])

    useEffect(() => {
        void refresh()
    }, [refresh])

    return [counts, loading, refresh, error] as [
        typeof counts,
        typeof loading,
        typeof refresh,
        typeof error
    ]
}

export default useCountFromServer
