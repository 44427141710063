import { useState, useCallback, useEffect } from 'react'
import { type ListResult, type StorageReference, listAll } from 'firebase/storage'

export interface ListAllOptions {
    disabled?: boolean
}

function useListAll (ref: StorageReference, options?: ListAllOptions) {
    const [result, setResult] = useState<ListResult>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const refresh = useCallback(async () => {
        setError(undefined)

        if (options?.disabled === true) {
            setResult(undefined)
            return
        }

        try {
            setLoading(true)
            setResult(await listAll(ref))
        } catch (error) {
            if (error instanceof Error) {
                setError(error)
            }
        } finally {
            setLoading(false)
        }
    }, [ref, options?.disabled])

    useEffect(() => {
        void refresh()
    }, [refresh])

    return [result, loading, refresh, error] as [
        typeof result,
        typeof loading,
        typeof refresh,
        typeof error
    ]
}

export default useListAll
