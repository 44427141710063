import { type FC, memo } from 'react'
import {
    Fade,
    LinearProgress as MuiLinearProgress,
    type LinearProgressProps as MuiLinearProgressProps
} from '@mui/material'

export interface LinearProgressProps extends MuiLinearProgressProps {
    loading?: boolean
}

export const LinearProgress: FC<LinearProgressProps> = (props) => {
    const { loading, ...others } = props

    return (
        <Fade in={loading}>
            <MuiLinearProgress
                {...others}
                value={100}
                variant={
                    loading === true
                        ? 'indeterminate'
                        : 'determinate'
                }
                sx={theme => ({
                    zIndex: theme.zIndex.snackbar,
                    background: 'inherit',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0
                })}
            />
        </Fade>
    )
}

export default memo(LinearProgress)
