import { type FC, memo } from 'react'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box } from '@mui/material'
import { appName } from '../config'
import type { DocumentData } from '../types'

export interface ProductProps {
    product: DocumentData
}

const Product: FC<ProductProps> = (props) => {
    const { product } = props
    const title = product.name ?? product.id

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
        >
            <Helmet>
                <title>{title} | {appName}</title>
                {typeof product.description === 'string' && (
                    <meta
                        name='description'
                        content={product.description}
                    />
                )}
            </Helmet>
            <Outlet />
        </Box>
    )
}

export default memo(Product)
