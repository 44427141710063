import { type FC, memo } from 'react'
import { Stack, Link, Divider } from '@mui/material'
import { TfiNewWindow } from 'react-icons/tfi'

const Footer: FC = () => {
    return (
        <Stack
            direction='row'
            justifyContent='center'
            padding={2}
            spacing={2}
        >
            <Link
                target='_blank'
                href='https://www.opsangels.com/terms-of-use'
                variant='body2'
                sx={{
                    color: 'text.secondary'
                }}
            >
                Terms of use <TfiNewWindow />
            </Link>
            <Divider
                flexItem
                orientation='vertical'
            />
            <Link
                target='_blank'
                href='https://www.opsangels.com/privacy-policy'
                variant='body2'
                sx={{
                    color: 'text.secondary'
                }}
            >
                Privacy policy <TfiNewWindow />
            </Link>
        </Stack>
    )
}

export default memo(Footer)
