import { type FC, memo, useContext, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import fileDownload from 'js-file-download'
import type { User } from 'firebase/auth'
import { limit, where } from 'firebase/firestore'
import {
    Toolbar,
    Tooltip,
    Breadcrumbs,
    IconButton,
    Link as MuiLink,
    Grid,
    Stack,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText
} from '@mui/material'
import { FiLink } from 'react-icons/fi'
import { TfiNewWindow } from 'react-icons/tfi'
import { firestore, resourceStorage, outputStorage } from '../firebase'
import type { DocumentData } from '../types'
import { useStorageRef, useDownloadURL, useGetBlob, useCollection, useSnapshot } from '../hooks'
import { AlertContext } from '../providers'
import { LinearProgress } from '../components'

export interface ProductViewerProps {
    user: User
    product: DocumentData
}

const ProductViewer: FC<ProductViewerProps> = (props) => {
    const { user, product } = props
    const params = useParams()
    const setAlert = useContext(AlertContext)
    const title = product.name ?? product.id
    const resourceURL = params['*'] ?? ''

    const breadcrumbs = useMemo(() => (
        resourceURL
            .split('/')
            .filter((breadcrumb) => breadcrumb.length > 0)
    ), [resourceURL])

    const resourceName = useMemo(() => {
        if (breadcrumbs !== undefined) {
            return breadcrumbs.at(-1)
        }
    }, [breadcrumbs])

    const resourceRef = useStorageRef(resourceStorage, {
        baseURL: `${user.uid}/${product.id}/`,
        url: resourceURL
    })

    const [resourceDownloadURL, resourceDownloadURLLoading] = useDownloadURL(resourceRef)

    const queryConstraints = useMemo(() => [[
        limit(1),
        where('name', '==', resourceRef.fullPath)
    ]], [resourceRef.fullPath])

    const [resourceCollection] = useCollection(firestore, `users/${user.uid}/resources`, queryConstraints)
    const [resourceDocs, resourceDocsLoading] = useSnapshot(resourceCollection)
    const resourceDoc = resourceDocs?.at(0)
    const outputURL = resourceDoc?.outputs?.at(0)?.name

    const outputName = useMemo(() => {
        if (typeof outputURL === 'string') {
            return outputURL.split('/').at(-1)
        }
    }, [outputURL])

    const outputRef = useStorageRef(outputStorage, {
        url: outputURL
    })

    const [getBlob, blobLoading] = useGetBlob()

    const startedAt = useMemo(() => {
        if (typeof resourceDoc?.startedAt?.seconds === 'number') {
            return new Date(resourceDoc.startedAt.seconds * 1000).toLocaleString([], {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                second: '2-digit'
            })
        }

        return '-'
    }, [resourceDoc?.startedAt?.seconds])

    const endedAt = useMemo(() => {
        if (typeof resourceDoc?.endedAt?.seconds === 'number') {
            return new Date(resourceDoc.endedAt.seconds * 1000).toLocaleString([], {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                second: '2-digit'
            })
        }

        return '-'
    }, [resourceDoc?.endedAt?.seconds])

    return (
        <>
            <LinearProgress loading={
                resourceDocsLoading ||
                resourceDoc?.status === 'Started' ||
                resourceDownloadURLLoading
            } />
            <Toolbar>
                <Tooltip title='Copy Path'>
                    <IconButton
                        sx={{
                            marginRight: 1
                        }}
                        onClick={() => {
                            void navigator
                                .clipboard
                                .writeText(window.location.href)
                        }}
                    >
                        <FiLink />
                    </IconButton>
                </Tooltip>
                <Breadcrumbs sx={{
                    flex: 1
                }}>
                    <MuiLink
                        to={`/product/${product.id}/explorer`}
                        component={Link}
                    >
                        {title}
                    </MuiLink>
                    {breadcrumbs?.map((breadcrumb, index) => {
                        let url = breadcrumbs
                            .slice(0, index + 1)
                            .join('/')

                        if (index < breadcrumbs.length - 1) {
                            url = `/product/${product.id}/explorer/${url}`
                        }

                        return (
                            <MuiLink
                                key={index}
                                to={url}
                                component={Link}
                            >
                                {breadcrumb}
                            </MuiLink>
                        )
                    })}
                </Breadcrumbs>
            </Toolbar>
            {resourceDoc !== undefined && resourceDownloadURL !== undefined && (
                <Grid
                    container
                    spacing={2}
                    paddingTop={2}
                >
                    {
                        typeof resourceDoc.contentType === 'string' &&
                        resourceDoc.contentType.startsWith('image') && (
                            <Grid
                                item
                                md={8}
                                xs={12}
                                container
                                alignItems='center'
                                justifyContent='center'
                            >
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: 600
                                    }}
                                    alt={resourceName}
                                    src={resourceDownloadURL}
                                />
                            </Grid>
                        )
                    }
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <Stack
                            spacing={2}
                            paddingX={1}
                        >
                            <Button
                                variant='contained'
                                disabled={outputName === undefined || blobLoading}
                                onClick={async () => {
                                    try {
                                        if (outputName !== undefined) {
                                            const blob = await getBlob(outputRef)
                                            fileDownload(blob, outputName)
                                        }
                                    } catch (error) {
                                        setAlert({
                                            severity: 'error',
                                            content: 'Download failed'
                                        })
                                    }
                                }}
                            >
                                Download {product.outputName ?? 'Output'}
                            </Button>
                            <Divider />
                        </Stack>
                        <List disablePadding>
                            <ListItem>
                                <ListItemText
                                    primary='Name'
                                    secondary={
                                        <MuiLink
                                            download
                                            target='_blank'
                                            href={resourceDownloadURL}
                                        >
                                            {resourceName} <TfiNewWindow />
                                        </MuiLink>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary='Status'
                                    secondary={resourceDoc.status ?? '-'}
                                    secondaryTypographyProps={{
                                        sx: {
                                            color: resourceDoc.status === 'Success'
                                                ? 'success.main'
                                                : resourceDoc.status === 'Error'
                                                    ? 'error.main'
                                                    : 'info.main'
                                        }
                                    }}
                                />
                            </ListItem>
                            {typeof resourceDoc.message === 'string' && (
                                <ListItem>
                                    <ListItemText
                                        primary='Message'
                                        secondary={resourceDoc.message}
                                    />
                                </ListItem>
                            )}
                            <ListItem>
                                <ListItemText
                                    primary='Size'
                                    secondary={
                                        typeof resourceDoc.size === 'string'
                                            ? `${resourceDoc.size} B`
                                            : '-'
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary='Type'
                                    secondary={resourceDoc.contentType ?? '-'}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary='Started At'
                                    secondary={startedAt}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary='Ended At'
                                    secondary={endedAt}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default memo(ProductViewer)
