import { useState, useCallback } from 'react'
import { type StorageReference, getBlob } from 'firebase/storage'

function useGetBlob () {
    const [loading, setLoading] = useState(false)

    const handleGetBlob = useCallback(async (ref: StorageReference) => {
        try {
            setLoading(true)
            const blob = await getBlob(ref)
            return blob
        } finally {
            setLoading(false)
        }
    }, [])

    return [handleGetBlob, loading] as [
        typeof handleGetBlob,
        typeof loading
    ]
}

export default useGetBlob
