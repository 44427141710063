import { type FC, type ReactNode, memo } from 'react'

export interface CenterProps {
    children: ReactNode
}

const Center: FC<CenterProps> = ({ children }) => {
    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {children}
        </div>
    )
}

export default memo(Center)
