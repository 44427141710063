import { useMemo } from 'react'
import { type Firestore, type QueryConstraint, collection, query } from 'firebase/firestore'

function useCollection (firestore: Firestore, path: string, queryConstraints?: QueryConstraint[][]) {
    return useMemo(() => {
        const collectionRef = collection(firestore, path)

        if (queryConstraints !== undefined && queryConstraints.length > 0) {
            return queryConstraints.map((queryConstraints) => {
                return query(collectionRef, ...queryConstraints)
            })
        }

        return [collectionRef]
    }, [firestore, path, queryConstraints])
}

export default useCollection
