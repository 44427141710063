import { type FC, type ReactNode, memo, useState, useContext, useEffect } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { type User, signOut } from 'firebase/auth'
import {
    FiMenu,
    FiArrowLeft,
    FiArrowRight,
    FiHome,
    FiMessageSquare,
    FiLogOut,
    FiMoon,
    FiFolder
} from 'react-icons/fi'
import {
    AppBar,
    Toolbar,
    Link as MuiLink,
    Avatar,
    Tooltip,
    Box,
    List,
    ListSubheader,
    Typography,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer,
    Container,
    Switch,
    Divider,
    useTheme,
    useMediaQuery
} from '@mui/material'
import { auth } from '../firebase'
import { ThemeContext, FeedbackContext, ProductsContext } from '../providers'
import { Logo, Icons } from '../components'

export interface AppContainerProps {
    user: User
    children: ReactNode
}

const AppContainer: FC<AppContainerProps> = (props) => {
    const { pathname } = useLocation()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('lg'))
    const [navOpen, setNavOpen] = useState(!mobile)
    const [profileOpen, setProfileOpen] = useState(false)
    const [mode, setMode] = useContext(ThemeContext)
    const toggleFeedback = useContext(FeedbackContext)
    const [products] = useContext(ProductsContext)
    const displayName = props.user.displayName ?? props.user.email ?? props.user.uid
    const photoURL = props.user.photoURL ?? undefined

    useEffect(() => {
        if (mobile) {
            setNavOpen(false)
        }
    }, [pathname, mobile])

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
        >
            <AppBar
                elevation={0}
                position='sticky'
                color='inherit'
            >
                <Toolbar>
                    <IconButton
                        size='medium'
                        aria-label='Open navigation'
                        sx={{
                            marginRight: 2
                        }}
                        onClick={() => {
                            setNavOpen(true)
                        }}
                    >
                        <FiMenu />
                    </IconButton>
                    <MuiLink
                        to='/'
                        aria-label='OpsAngels'
                        sx={{
                            display: 'flex'
                        }}
                        component={Link}
                    >
                        <Logo height={30} />
                    </MuiLink>
                    <Box flex={1} />
                    <Tooltip title={displayName}>
                        <IconButton
                            aria-label='Open profile'
                            onClick={() => {
                                setProfileOpen(true)
                            }}
                        >
                            <Avatar
                                alt={displayName}
                                src={photoURL}
                                sx={{
                                    width: 30,
                                    height: 30
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                {!mobile && <Divider />}
            </AppBar>
            <Box
                flex={1}
                display='flex'
            >
                <Drawer
                    sx={{
                        width: (navOpen || mobile) ? 280 : undefined
                    }}
                    PaperProps={{
                        sx: {
                            width: 280,
                            boxSizing: 'border-box'
                        }
                    }}
                    open={navOpen}
                    variant={mobile ? 'temporary' : 'persistent'}
                    onClose={() => {
                        setNavOpen(false)
                    }}
                >
                    <Toolbar>
                        <Logo height={30} />
                        <Box flex={1} />
                        <IconButton
                            size='medium'
                            aria-label='Close navigation'
                            onClick={() => {
                                setNavOpen(false)
                            }}
                        >
                            <FiArrowLeft />
                        </IconButton>
                    </Toolbar>
                    {!mobile && <Divider />}
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to='/'
                                selected={pathname === '/'}
                            >
                                <ListItemIcon>
                                    <FiHome />
                                </ListItemIcon>
                                <ListItemText primary='Home' />
                            </ListItemButton>
                        </ListItem>
                        <ListSubheader>
                            Tools
                        </ListSubheader>
                        {products?.map((product) => {
                            const Icon = Icons[product.icon]

                            return (
                                <ListItem
                                    key={product.id}
                                    disablePadding
                                >
                                    <ListItemButton
                                        component={Link}
                                        to={`/product/${product.id}/explorer`}
                                        selected={Boolean(matchPath(`/product/${product.id}/*`, pathname))}
                                    >
                                        <ListItemIcon>
                                            {Icon !== undefined ? <Icon /> : <FiFolder />}
                                        </ListItemIcon>
                                        <ListItemText primary={product.name ?? product.id} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Drawer>
                <Container
                    disableGutters
                    sx={{
                        flex: 1,
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {props.children}
                </Container>
            </Box>
            <Drawer
                keepMounted
                sx={{
                    width: 280
                }}
                PaperProps={{
                    sx: {
                        width: 280,
                        boxSizing: 'border-box'
                    }
                }}
                open={profileOpen}
                anchor='right'
                onClose={() => {
                    setProfileOpen(false)
                }}
            >
                <Toolbar>
                    <IconButton
                        size='medium'
                        aria-label='Close profile'
                        sx={{
                            marginRight: 2
                        }}
                        onClick={() => {
                            setProfileOpen(false)
                        }}
                    >
                        <FiArrowRight />
                    </IconButton>
                    <Typography
                        noWrap
                        variant='h6'
                    >
                        Profile
                    </Typography>
                </Toolbar>
                {!mobile && <Divider />}
                <List sx={{
                    flex: 1
                }}>
                    <ListItem>
                        <ListItemIcon>
                            <Avatar
                                alt={displayName}
                                src={photoURL}
                                sx={{
                                    width: 40,
                                    height: 40
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={displayName}
                            secondary={displayName === props.user?.email ? '' : props.user?.email}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiMoon />
                        </ListItemIcon>
                        <ListItemText primary='Dark Mode' />
                        <Switch
                            name='darkMode'
                            checked={mode === 'dark'}
                            onChange={(_event, checked) => {
                                setMode(checked ? 'dark' : 'light')
                            }}
                            inputProps={{
                                'aria-label': 'Toggle dark mode'
                            }}
                        />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            toggleFeedback()
                            setProfileOpen(false)
                        }}>
                            <ListItemIcon>
                                <FiMessageSquare />
                            </ListItemIcon>
                            <ListItemText primary='Send Feedback' />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListSubheader>
                        Other Products
                    </ListSubheader>
                    <ListItem disablePadding>
                        <ListItemButton
                            target='_blank'
                            href='https://www.segment360.com/'
                            component={MuiLink}
                        >
                            <ListItemIcon>
                                <Segment360 height={20} />
                            </ListItemIcon>
                            <ListItemText primary='Segment360' />
                        </ListItemButton>
                    </ListItem> */}
                </List>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            void signOut(auth)
                        }}>
                            <ListItemIcon>
                                <FiLogOut />
                            </ListItemIcon>
                            <ListItemText primary='Sign Out' />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    )
}

export default memo(AppContainer)
