import { useState, useCallback } from 'react'
import { type StorageReference, type UploadMetadata, uploadBytesResumable } from 'firebase/storage'

export interface UploadItem {
    ref: StorageReference
    data: Blob
    metadata?: UploadMetadata
}

function useUploadBytes () {
    const [progress, setProgress] = useState<number>()
    const [item, setItem] = useState<UploadItem>()

    const upload = useCallback(async (items: UploadItem[]) => {
        try {
            for (const item of items) {
                setProgress(0)
                setItem(item)
                const { ref, data, metadata } = item
                const uploadTask = uploadBytesResumable(ref, data, metadata)

                await new Promise<void>((resolve, reject) => {
                    uploadTask.on('state_changed',
                        ({ bytesTransferred, totalBytes }) => {
                            setProgress(Math.round((bytesTransferred / totalBytes) * 100))
                        },
                        reject,
                        resolve
                    )
                })
            }
        } finally {
            setProgress(undefined)
            setItem(undefined)
        }
    }, [])

    return [upload, progress, item] as [
        typeof upload,
        typeof progress,
        typeof item
    ]
}

export default useUploadBytes
